import { ConfidentialityMap, NavLink } from 'types';

const confidentialityMap: ConfidentialityMap = {
  confidential_ga: {
    label: 'Confidential General and Administrative - Internal Use Only',
    type: 'confidential',
  },
  confidential_hr: {
    label: 'Confidential Human Resources - Internal Use Only',
    type: 'confidential',
  },
  confidential_ms: {
    label: 'Confidential Marketing and Sales - Internal Use Only',
    type: 'confidential',
  },
  confidential_fa: {
    label: 'Confidential Finance and Accounting - Internal Use Only',
    type: 'confidential',
  },
  internal_ga: {
    label: 'Internal Use Only General and Administrative',
    type: 'internal',
  },
};

export const links: Array<NavLink> = [
  {
    id: 'home',
    label: 'Home',
    url: '/home',
    confidentiality: confidentialityMap.confidential_ga,
    internalSections: [
      {
        sectionLabel: '',
        links: [
          {
            id: 'prioritizedAgenda',
            label: 'Prioritized Agenda',
            url: '/prioritized-agenda',
            disabled: false,
          },
          {
            id: 'people',
            label: 'People',
            url: '/people',
            disabled: false,
          },
          {
            id: 'brand',
            label: 'Brand',
            url: '/brand',
            disabled: false,
          },
          {
            id: 'supply',
            label: 'Supply',
            url: '/supply',
            disabled: false,
          },
          {
            id: 'pipeline',
            label: 'Pipeline',
            url: '/pipeline',
            disabled: false,
          },
          {
            id: 'finance',
            label: 'Finance',
            url: '/finance',
            disabled: false,
          },
          {
            id: 'external',
            label: 'Intelligence',
            url: '/intelligence',
            disabled: false,
          },
          {
            id: 'notificationsSubscriptions',
            label: 'Notifications',
            url: '/subscriptions',
            disabled: false,
          },
        ],
      },
    ],
  },
  {
    id: 'prioritizedAgenda',
    label: 'Prioritized Agenda',
    url: '/prioritized-agenda',
    confidentiality: confidentialityMap.confidential_ga,
  },
  {
    id: 'people',
    label: 'People',
    url: '/people',
    confidentiality: confidentialityMap.confidential_hr,
    internalSections: [
      {
        sectionLabel: '',
        links: [
          {
            label: 'Key Products',
            url: '#key-products',
            disabled: false,
          },
          {
            label: 'Culture',
            url: '#culture',
            disabled: false,
          },
          {
            label: 'Talent Attraction',
            url: '#talent-attraction',
            disabled: false,
          },
          {
            label: 'Talent Development',
            url: '#talent-development',
            disabled: false,
          },
          {
            label: 'Talent Retention',
            url: '#talent-retention',
            disabled: false,
          },
          {
            label: 'Productivity',
            url: '#productivity',
            disabled: false,
          },
        ],
      },
    ],

    externalSections: [
      {
        sectionLabel: 'Related Links',
        links: [
          {
            label: 'Visier',
            url: 'https://prod-ac00.app.visier.com/hr/prod/appcontainer',
            disabled: false,
          },
          {
            label: 'Glint',
            url: 'https://app.glintinc.com/session/auth?clientUuid=amgen',
            disabled: false,
          },
        ],
      },
    ],
  },
  {
    id: 'brand',
    label: 'Brand',
    url: '/brand',
    confidentiality: confidentialityMap.confidential_ms,
    internalSections: [
      {
        sectionLabel: '',
        links: [
          {
            label: 'Brands at a Glance',
            url: '#brand-at-glance',
            disabled: false,
          },
          {
            label: 'TEPEZZA',
            url: '#tepezza',
            disabled: false,
          },
          {
            label: 'Repatha',
            url: '#repatha',
            disabled: false,
          },
          {
            label: 'Prolia',
            url: '#prolia',
            disabled: false,
          },
          {
            label: 'EVENITY',
            url: '#evenity',
            disabled: false,
          },
          {
            label: 'Otezla',
            url: '#otezla',
            disabled: false,
          },
          {
            label: 'TEZSPIRE',
            url: '#tezspire',
            disabled: false,
          },
          {
            label: 'Enbrel',
            url: '#enbrel',
            disabled: false,
          },
          {
            label: 'KRYSTEXXA',
            url: '#krystexxa',
            disabled: false,
          },
          {
            label: 'UPLIZNA',
            url: '#uplizna',
            disabled: false,
          },
          {
            label: 'LUMAKRAS',
            url: '#lumakras',
            disabled: false,
          },
          {
            label: 'BLINCYTO',
            url: '#blincyto',
            disabled: false,
          },
          {
            label: 'TAVNEOS',
            url: '#tavneos',
            disabled: false,
          },
          {
            label: 'Global Brand Sales Drivers',
            url: '#global',
            disabled: false,
          },
        ],
      },
    ],
  },
  {
    id: 'supply',
    label: 'Supply',
    url: '/supply',
    confidentiality: confidentialityMap.confidential_ga,
  },
  {
    id: 'pipeline',
    label: 'Pipeline',
    url: '/pipeline',
    confidentiality: confidentialityMap.confidential_ga,
    jumpToSections: [
      {
        sectionLabel: '',
        links: [
          {
            label: 'Key Events',
            url: '#key-events',
            disabled: false,
          },
          {
            label: 'Key Products',
            url: '#key-products',
            disabled: false,
          },
          {
            label: 'Product Reference',
            url: '#product-reference',
            disabled: false,
          },
        ],
      },
    ],
    externalSections: [
      {
        sectionLabel: 'Related Links',
        links: [
          {
            label: 'Mercury Portal',
            url: 'https://portfolio-analytics.amgen.com/',
            disabled: false,
          },
          {
            label: 'Key Events Dashboard',
            url: 'https://portfolio-analytics.amgen.com/dashboard/key-events-dashboard-key-events-dashboard',
            disabled: false,
          },
          {
            label: 'Program Level Dashboard',
            url: 'https://portfolio-analytics.amgen.com/dashboard/program-level?Prioritized%20Agenda%20Toggle=CEO%20Staff%20Tracked',
            disabled: false,
          },
          {
            label: 'Enrollment Indicator Dashboard',
            url: 'https://tspotfireweb.amgen.com/spotfire/wp/OpenAnalysis?file=/GDO%20%28Global%20Development%20Operations%29/Global%20Development%20Operations/TAG%20Dashboard/TAG%20Dashboard&configurationBlock=SetPage%28pageIndex%3D0%29%3B&options=7-1,9-1,10-1,11-1,12-1,13-1,14-1,1-1,2-1,3-1,4-1,5-1,6-0,15-1,17-1',
            disabled: false,
          },
          {
            label: 'List of Product Team Members',
            url: 'https://portfolio-analytics.amgen.com/dashboard/tlm-report',
            disabled: false,
          },
          {
            label: 'R&D KPI Dashboard',
            url: 'https://amgen.sharepoint.com/sites/RDKPIDashboard',
            disabled: false,
          },
        ],
      },
    ],
  },

  {
    id: 'finance',
    label: 'Finance',
    url: '/finance',
    confidentiality: confidentialityMap.confidential_fa,
  },
  {
    id: 'external',
    label: 'Intelligence',
    url: '/intelligence',
    confidentiality: confidentialityMap.confidential_ga,
  },
  {
    id: 'notificationsSubscriptions',
    label: 'Notifications',
    url: '/subscriptions',
    confidentiality: confidentialityMap.confidential_ga,
    internalSections: [
      {
        sectionLabel: '',
        links: [
          {
            label: 'Subscribed Notifications',
            url: '/subscriptions',
            disabled: false,
          },
          {
            label: 'Available Notifications',
            url: '/subscriptions/available',
            disabled: false,
          },
        ],
      },
    ],
  },
  {
    id: 'about',
    label: 'About',
    url: '/about',
    confidentiality: confidentialityMap.confidential_ga,
  },
  {
    id: 'admin',
    label: 'Admin',
    url: '/admin',
    confidentiality: confidentialityMap.confidential_ga,
    internalSections: [
      {
        sectionLabel: '',
        links: [
          {
            label: 'Usage Metric',
            url: '/admin/usage-metric',
            disabled: false,
          },
          {
            label: 'User Audit',
            url: '/admin/user-audit',
            disabled: false,
          },
          {
            label: 'Access Request',
            url: '/admin/sensing-access-request',
            disabled: false,
          },
          {
            label: 'Access Logs',
            url: '/admin/access-logs',
            disabled: false,
          },
          {
            label: 'Release Notes',
            url: '/admin/release-notes',
            disabled: false,
          },
          {
            label: 'Job Monitoring',
            url: '/admin/etl-monitoring',
            disabled: false,
          },
          {
            label: 'Update Sensing Constants',
            url: '/admin/sensing-constants',
            disabled: false,
          },
          {
            label: 'Refresh Brand',
            url: '/admin/refresh-brand',
            disabled: false,
          },
        ],
      },
    ],
    externalSections: [
      {
        sectionLabel: 'Related Links',
        links: [
          {
            label: 'Matomo',
            url: 'https://matomo-ext.devops.amgen.com/index.php?module=CoreHome&action=index&idSite=37&period=day&date=yesterday#?period=day&date=yesterday&category=Dashboard_Dashboard&subcategory=1',
            disabled: false,
          },
          {
            label: 'Data Element Inventory',
            url: 'https://amgen.sharepoint.com/:x:/s/ADI-DataMgmtCoreTeam/Ebt-VO-FQiZCqTkYho99qXwBvr1HeH3QrFSZmvrDnjdBWg?e=j96Eg8',
            disabled: false,
          },
          {
            label: 'Sensing Anchor Links',
            url: 'https://amgen.sharepoint.com/:w:/r/sites/ADISensing-ProductManagement/_layouts/15/Doc.aspx?sourcedoc=%7B870D361A-40C6-43CA-B41C-E293B03A958C%7D&file=Sensing%20Anchor%20Links%20.docx&action=default&mobileredirect=true',
            disabled: false,
          },
          {
            label: 'Grafana Cron Job Dashboard',
            url: 'https://grafana.nimbus.amgen.com/d/fd2f1623-f13d-4301-9987-edfb9195a490/cronjob-status-for-prod-and-stage-env?orgId=1',
            disabled: false,
          },
        ],
      },
    ],
  },
  {
    id: 'horizon',
    label: 'Horizon',
    url: '/horizon',
    confidentiality: confidentialityMap.internal_ga,
	externalSections: [
      {
        sectionLabel: 'Related Links',
        links: [
          {
            label: 'GO TO PMO DASHBOARD',
            url: 'https://app.powerbi.com/groups/me/reports/44981f0d-6290-4e16-9bad-5a490e263eb2/ReportSection7d1e47bf35ff85699665?ctid=4b4266a6-1368-41af-ad5a-59eb634f7ad8&experience=power-bi',
            disabled: false,
          },{
            label: 'GO TO HUB AND SEARCH DASHBOARD',
            url: 'https://amgen.sharepoint.com/:p:/r/sites/DTI-HorizonIntegration/Shared%20Documents/PMO/Tools%20and%20Resources/Sensing%20Dashboard/Hub%20and%20Search%20Dashboard%20Oct%209-22.pptx?d=weea1719337fc4fbe91e4e0814a3fbf72&csf=1&web=1&e=639ZsC',
            disabled: false,
          },{
            label: 'GO TO R&D DASHBOARD',
            url: 'https://amgen.sharepoint.com/:p:/r/sites/DTI-HorizonIntegration/Shared%20Documents/PMO/Tools%20and%20Resources/Sensing%20Dashboard/Horizon%20Asset%20Timelines_Dec%202023.pptx?d=w5fa91589252f4f43a6cd345a23f26370&csf=1&web=1&e=tdu7fY',
            disabled: false,
          },
        ],
      },
    ]
  },
];
